<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">企业办事</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">智能选址</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">选地块</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/SmartLocation' }">选楼栋</el-breadcrumb-item>
      <el-breadcrumb-item>选房</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="" style="display:flex;width:100vw;flex-wrap: wrap;">
      <template v-for="no in '123567'" >
          <template v-for="f in 5">
                  <RoomSelector :id="`${no}${f}`" :width="400" :key="`${no}${f}`" ></RoomSelector>
          </template>
      </template>
    </div>
   

    
  </div>
</template>
<script>
import { mapState } from 'vuex';

import RoomSelector from "./components/RoomSelector/index.vue";
export default {
  data() {
    var validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入联系电话'));
      } else if (!this.isValidPhone(value)) {
        callback(new Error('请输入正确的联系电话'));
      } else {
        callback();
      }
    };
    return {
      build: 2,
      land: 1,
      list: [],
      centerDialogVisible: false,
      formData: {
        contactPerson: '',
        phone: '',
      },
      rules: {
        contactPerson: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, validator: validPhone, trigger: 'blur' }],
      },
    };
  },
  created() {
    this.build = this.$route.params.build;
    this.land = this.$route.params.land;
    this.getData();
  },
  components: {
    RoomSelector,
  },
  methods: {
    isValidPhone(str) {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      return reg.test(str);
    },
    apply(id) {
      this.formData.roomId = id;
      this.centerDialogVisible = true;
    },
    closeDialog() {
      // 点击dialog右上角关闭按钮事件
      this.$refs.from.resetFields();
      this.formData = {
        contactPerson: '',
        phone: '',
      };
    },
    submit() {
      this.$refs['from'].validate((valid) => {
        if (valid) {
          this.$api.buildingLeaseApply(this.formData).then((res) => {
            if (res.success) {
              this.$message.success({ message: '申请成功', offset: 80 });
              this.centerDialogVisible = false;
              this.getData();
            }
          });
        } else {
          return false;
        }
      });
    },
    getData() {
      this.$api.getBuildingleaseList({ land: this.land, build: this.build }).then((res) => {
        if (res.success) {
          this.list = res.result.map((v) => {
            v.show = false;
            v.info = null;
            return v;
          });
          let floorList = res.result.map((v) => {
            return v.floor;
          });
          this.$store.commit('updateValue', { floorList: floorList });
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    go() {
      this.$router.push({ name: 'smartLocationOverview', params: { land: this.land, build: this.build } });
    },
    select(v, item) {
      item.info = v;
    },
    getlayer(num) {
      num = Number(num);
      var upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
      var length = String(num).length;
      if (length == 1) {
        return upperCaseNumber[num];
      } else if (length == 2) {
        if (num == 10) {
          return upperCaseNumber[num];
        } else if (num > 10 && num < 20) {
          return '十' + upperCaseNumber[String(num).charAt(1)];
        } else {
          return upperCaseNumber[String(num).charAt(0)] + '十' + upperCaseNumber[String(num).charAt(1)].replace('零', '');
        }
      }
    },
  },
  computed: {
    ...mapState({
      notLeasedNum: (state) => state.notLeasedNum,
    }),
  },
  mounted() {},
};
</script>


<style lang='scss' scoped>
.session {
  padding: 0 20px 22px;
  background-color: #fff;
}

.title {
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #000000;
  line-height: 51px;
  margin-bottom: 16px;
}

.title-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.back {
  cursor: pointer;
}

.item-title {
  font-size: 14px;
  color: #000;
  padding-left: 16px;
  position: relative;
  margin-bottom: 16px;
}

.item-title::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #0e459c;
}

.header-box {
  background: #eff7ff;
  font-size: 16px;
  color: #000000;
  line-height: 95px;
  padding: 0 20px;
  margin-bottom: 16px;
}

.number {
  font-size: 21px;
  color: #000000;
  margin-right: 20px;
}

.link {
  color: #0e459c;
  text-decoration: none;
  cursor: pointer;
}

.layer {
  background: #fafafa;
  border: 1px solid #eeeeee;
  margin-bottom: 16px;
  padding: 20px;
}

.layer-number {
  font-size: 18px;
  color: #333333;
  font-weight: 600;
}

.sq-zk {
  background: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 96px;
  height: 40px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.show-img {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  vertical-align: middle;
}

.sq {
  margin-top: 14px;
}

.floor-plan {
  margin-top: -10px;
}

.room-card {
  background: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(97, 143, 221, 0.26);
  border-radius: 4px;
  width: 364px;
  height: max-content;
  padding: 20px;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}

.room-box {
  margin-right: 20px;
  width: calc(100% - 384px);
  height: 450px;
  border: 10px solid #0e459c;
  background: #fff;
  font-size: 14px;
  color: #333333;
  text-align: center;
}

.no-select {
  line-height: 100px;
  text-align: center;
  font-size: 16px;
  color: #999999;
}

.room-status {
  display: inline-block;
  font-size: 10px;
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
  margin-left: 6px;
}

.status1 {
  color: #0e459c;
  background: rgba(74, 126, 255, 0.15);
}

.status2 {
  color: #d18308;
  background: rgba(228, 157, 46, 0.15);
}

.room-name {
  margin-bottom: 20px;
}

.room-card-box {
  width: 140px;
  height: 140px;
  margin-right: 16px;
  background: #fafafa;
  margin-bottom: 16px;
  color: #333333;
  position: relative;
  text-align: center;
  line-height: 150px;
  z-index: 1;
  img {
    display: block;
    max-width: 95%;
    max-height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
.enterprise-name {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}

.room-info {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-bottom: 10px;
}
.room-card .btn {
  height: 40px;
  line-height: 20px;
  padding: 10px 25px;
}
.room-card .disabled.btn {
  background: #cccccc;
  color: #fff;
}
</style>